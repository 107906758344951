import React, { useState } from "react";
import classes from "./InfoContainer.module.css";
import { avatar, fullScreenIcon, musicIcon } from "images";
import { Button, Heading, Text } from "components/common";
import clsx from "clsx";
import { FaLink } from "react-icons/fa6";
import { TfiWorld } from "react-icons/tfi";
import { FiPlus } from "react-icons/fi";
import { HiOutlineExternalLink } from "react-icons/hi";
import { MdClose } from "react-icons/md";

const InfoContainer = () => {
  const [isFullscreen, setIsFullscreen] = useState(false);

  const toggleFullscreen = () => {
    setIsFullscreen((prev) => !prev);
  };

  const links = [
    {
      title: "Governed by DAO",
      icon: <TfiWorld className={classes.icon} />,
      link: "https://www.youtube.com/watch?v=zzE3fpOqJbc",
    },
    // {
    //   title: "View metadata",
    //   icon: <FaLink className={classes.icon} />,
    //   link: "#",
    // },
    // {
    //   title: "View on IPFS",
    //   icon: <FaLink className={classes.icon} />,
    //   link: "#",
    // },
  ];
  return (
    <section className={classes.wrapper}>
      {/* image */}
      <div
        className={clsx(
          classes.imgContainer,
          isFullscreen && classes.fullscreen
        )}
      >
        <div className={classes.topBar}>
          {/* <button className={classes.music}>
            <img src={musicIcon} alt="Music" className={classes.musicIcon} />
          </button>{" "} */}
          <button className={classes.fullScreen} onClick={toggleFullscreen}>
            {isFullscreen ? (
              <MdClose className={classes.close} />
            ) : (
              <img
                src={fullScreenIcon}
                alt="Music"
                className={classes.fullScreenIcon}
              />
            )}
          </button>
        </div>
        <img
          src={
            "https://storage.googleapis.com/uns_properties/rep_nft/repnft.gif"
          }
          width={10}
          alt="#"
          className={classes.img}
        />
      </div>{" "}
      <div className={clsx(classes.infoContainer)}>
        {/* authors */}
        {/* <div
          className={clsx(classes.createdByAndCollection, classes.container)}
        >
          <div className={classes.createdBy}>
            <Text sm neutral5 className={classes.label}>
              Created by
            </Text>
            <div className={classes.authorImgContainer}>
              <img src={"https://i.ibb.co/GtwPTL9/uns-round.jpg"} width={40} alt="#" className={classes.authorImg} />
            </div>
            <div className={classes.logIn}>
              <Text base neutral7 className={classes.author}>
                UNS Technologies
              </Text>
              <Text sm neutral5 className={classes.login}>
                @unstoken_
              </Text>
            </div>
          </div>
        </div> */}
        <hr className={classes.hr} />
        {/* details */}
        <div className={clsx(classes.detailsContainer, classes.container)}>
          {/* <Heading h4 neutral7>
            Details
          </Heading> */}
          <Text justify semiBold xl neutral5>
            REPNFT is a REAL ESTATE NFT that serves as a digital representation
            of the real world properties. It gives the buyer (you) a fractional
            ownership in the properties that UNS purchases across the Globe.
            It's built on Blockchain Technology that functions independently in
            order to reduce reliance on intermediaries. Tokenization is adopted
            here to divide the properties bought into unique tradable tokens,
            increasing liquidity and accessibility. REPNFT functions through
            smart contract for ownership transfers. The NFT market is still
            evolving, and volatility is inherent.
          </Text>
          <hr className={clsx(classes.hr, classes.container)} />
          <div className={classes.links}>
            {links.map((link, i) => (
              <a
                href={link.link}
                target="_blank"
                rel="noreferrer"
                key={i}
                className={classes.link}
              >
                {link.icon}{" "}
                <Text base neutral7>
                  {link.title}
                </Text>
              </a>
            ))}
          </div>
          {/* <Button className={classes.addTag} transparent>
            Add tags <FiPlus />
          </Button> */}
        </div>{" "}
        <hr className={classes.hr} />
        {/* provenance */}
      </div>
    </section>
  );
};


export const BuyContainer = ({ nft }) => {
  console.log(nft)
  const [isFullscreen, setIsFullscreen] = useState(false);

  const toggleFullscreen = () => {
    setIsFullscreen((prev) => !prev);
  };

  const links = [
    {
      title: "Governed by DAO",
      icon: <TfiWorld className={classes.icon} />,
      link: "https://www.youtube.com/watch?v=zzE3fpOqJbc",
    },
    // {
    //   title: "View metadata",
    //   icon: <FaLink className={classes.icon} />,
    //   link: "#",
    // },
    // {
    //   title: "View on IPFS",
    //   icon: <FaLink className={classes.icon} />,
    //   link: "#",
    // },
  ];
  return (
    <section className={classes.wrapper}>
      {/* image */}
      <div
        className={clsx(
          classes.imgContainer,
          isFullscreen && classes.fullscreen
        )}
      >
        <div className={classes.topBar}>
          {/* <button className={classes.music}>
            <img src={musicIcon} alt="Music" className={classes.musicIcon} />
          </button>{" "} */}
          <button className={classes.fullScreen} onClick={toggleFullscreen}>
            {isFullscreen ? (
              <MdClose className={classes.close} />
            ) : (
              <img
                src={fullScreenIcon}
                alt="Music"
                className={classes.fullScreenIcon}
              />
            )}
          </button>
        </div>
        <img src={nft?.img} width={10} alt="#" className={classes.img} />
      </div>{" "}
      <div className={clsx(classes.infoContainer)}>
        {/* authors */}
        {/* <div
          className={clsx(classes.createdByAndCollection, classes.container)}
        >
          <div className={classes.createdBy}>
            <Text sm neutral5 className={classes.label}>
              Created by
            </Text>
            <div className={classes.authorImgContainer}>
              <img src={"https://i.ibb.co/GtwPTL9/uns-round.jpg"} width={40} alt="#" className={classes.authorImg} />
            </div>
            <div className={classes.logIn}>
              <Text base neutral7 className={classes.author}>
                UNS Technologies
              </Text>
              <Text sm neutral5 className={classes.login}>
                @unstoken_
              </Text>
            </div>
          </div>
        </div> */}
        <hr className={classes.hr} />
        {/* details */}
        <div className={clsx(classes.detailsContainer, classes.container)}>
          {/* <Heading h4 neutral7>
            Details
          </Heading> */}
          <Text justify semiBold xl neutral5>
            {/* REPNFT is a REAL ESTATE NFT that serves as a digital representation of
            the real world properties. It gives the buyer (you) a fractional
            ownership in the properties that UNS purchases across the Globe.
            It's built on Blockchain Technology that functions independently in
            order to reduce reliance on intermediaries. Tokenization is adopted
            here to divide the properties bought into unique tradable tokens,
            increasing liquidity and accessibility. REPNFT functions through smart
            contract for ownership transfers. The NFT market is still evolving,
            and volatility is inherent. */}
          </Text>
          <hr className={clsx(classes.hr, classes.container)} />
          <div className={classes.links}>
            {links.map((link, i) => (
              <a
                href={link.link}
                target="_blank"
                rel="noreferrer"
                key={i}
                className={classes.link}
              >
                {link.icon}{" "}
                <Text base neutral7>
                  {link.title}
                </Text>
              </a>
            ))}
          </div>
          {/* <Button className={classes.addTag} transparent>
            Add tags <FiPlus />
          </Button> */}
        </div>{" "}
        <hr className={classes.hr} />
        {/* provenance */}
      </div>
    </section>
  );
};

export default InfoContainer;
