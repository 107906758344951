import classes from "./BuyNow.module.css";

import InfoContainer, { BuyContainer } from "components/BuyNow/InfoContainer/InfoContainer";

import Buy from "components/BuyNow/Buy/Buy";
import Sale from "components/SaleNow/Sale/Sale";
import { useParams } from "react-router-dom";

const BuyNowPage = ({ sales, nftData }) => {
  let { nft_id } = useParams();

  console.log(nft_id)
  let nft = nftData.find((nft) => nft.id == nft_id);
  return (
    <section className={classes.wrapper}>
      <BuyContainer nft={nft} />
      {sales ? <Sale /> : <Buy nft={nft} />}
    </section>
  );
};

export default BuyNowPage;
