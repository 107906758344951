import Navbar from "components/Navbar/Navbar";
import MainLayout from "Layout/MainLayout";
import BuyNowPage from "pages/BuyNow/BuyNow";
import Discover from "pages/Discover/Discover";
import Home from "pages/Home/Home";
import Nfts from "pages/Nfts/Nfts";
import { Route, Routes } from "react-router-dom";
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState } from "react";
import { escapeImg } from "images";
function App() {
  const [nftData, setNftData] = useState([
    {
      id: 0,
      is_presale: true,
      img: "https://storage.googleapis.com/uns_properties/rep_nft/repnft.gif",
      userImg: "https://storage.googleapis.com/uns_properties/rep_nft/repnft.gif",
      author: "REAL ESTATE NFT",
      avatar: escapeImg,
      label: "ESCP",
      currentBid: "~",
      price: "329 USDT",
      verified: true,
      status: "Buy now",
      special_link: "/sales/repnft"
    },
    {
      id: 1,
      img: "https://airnfts.s3.amazonaws.com/nfts-images/202210261610350/WOLFE_WOLF_ROBBER_1666803140719.jpeg",
      userImg: "https://airnfts.s3.amazonaws.com/nfts-images/202210261610350/WOLFE_WOLF_ROBBER_1666803140719.jpeg",
      author: "WOLFE WOLF ROBBER",
      avatar: "escapeImg",
      label: "ESCP",
      currentBid: "~",
      price: "2000",
      verified: true,
      status: "Auction",
    },
    {
      id: 2,
      img: "https://airnfts.s3.amazonaws.com/nft-images/20220812/FunkyApeClub21_1660302179467.jpeg",
      userImg: "https://airnfts.s3.amazonaws.com/nft-images/20220812/FunkyApeClub21_1660302179467.jpeg",
      author: "FunkyApeClub",
      avatar: "escapeImg",
      label: "ESCP",
      currentBid: "~",
      price: "3500",
      verified: true,
      status: "Auction",
    },
    {
      id: 3,
      img: "https://d1don5jg7yw08.cloudfront.net/800x800/nft-images/20220322/Degeneration_1647947709007.png",
      userImg: "https://d1don5jg7yw08.cloudfront.net/800x800/nft-images/20220322/Degeneration_1647947709007.png",
      author: "Degeneration",
      avatar: "escapeImg",
      label: "ESCP",
      currentBid: "~",
      price: "3000",
      verified: true,
      status: "Auction",
    },
    {
      id: 4,
      img: "https://airnfts.s3.amazonaws.com/nfts-images/202209132309790/GoldkhaanDiamond_bo_1663113107321.jpeg",
      userImg: "https://airnfts.s3.amazonaws.com/nfts-images/202209132309790/GoldkhaanDiamond_bo_1663113107321.jpeg",
      author: "Goldkhaan-Diamond box",
      avatar: "escapeImg",
      label: "ESCP",
      currentBid: "~",
      price: "3000",
      verified: true,
      status: "Auction",
    },
    {
      id: 5,
      img: "https://d1don5jg7yw08.cloudfront.net/800x800/nfts-images/202209181609324/The_Three_Musketeers_1663517556617.jpeg",
      userImg: "https://d1don5jg7yw08.cloudfront.net/800x800/nfts-images/202209181609324/The_Three_Musketeers_1663517556617.jpeg",
      author: "The Three Musketeers",
      avatar: "escapeImg",
      label: "ESCP",
      currentBid: "~",
      price: "5000",
      verified: true,
      status: "Auction",
    },
    {
      id: 6,
      img: "https://d1don5jg7yw08.cloudfront.net/800x800/nft-images/20220611/Sharknado_1654959318352.jpeg",
      userImg: "https://d1don5jg7yw08.cloudfront.net/800x800/nft-images/20220611/Sharknado_1654959318352.jpeg",
      author: "Sharknado",
      avatar: "escapeImg",
      label: "ESCP",
      currentBid: "~",
      price: "2000",
      verified: true,
      status: "Auction",
    },
    {
      id: 7,
      img: "https://d1don5jg7yw08.cloudfront.net/800x800/nft-images/20220417/Halloween_Squad_0011_1650211949775.jpg",
      userImg: "https://d1don5jg7yw08.cloudfront.net/800x800/nft-images/20220417/Halloween_Squad_0011_1650211949775.jpg",
      author: "Haloween Squad",
      avatar: "escapeImg",
      label: "ESCP",
      currentBid: "~",
      price: "2000",
      verified: true,
      status: "Auction",
    },
    {
      id: 8,
      img: "https://d1don5jg7yw08.cloudfront.net/800x800/nft-images/20220402/Display_Ape_05_1648940176961.png",
      userImg: "https://d1don5jg7yw08.cloudfront.net/800x800/nft-images/20220402/Display_Ape_05_1648940176961.png",
      author: "Display Ape",
      avatar: "escapeImg",
      label: "ESCP",
      currentBid: "~",
      price: "2000",
      verified: true,
      status: "Auction",
    },
    {
      id: 9,
      img: "https://d1don5jg7yw08.cloudfront.net/800x800/nft-images/20220309/Cutie_Kitties_7_1646828348081.jpg",
      userImg: "https://d1don5jg7yw08.cloudfront.net/800x800/nft-images/20220309/Cutie_Kitties_7_1646828348081.jpg",
      author: "Cutie Kitties",
      avatar: "escapeImg",
      label: "ESCP",
      currentBid: "~",
      price: "2000",
      verified: true,
      status: "Auction",
    },
    {
      id: 10,
      img: "https://d1don5jg7yw08.cloudfront.net/800x800/nft-images/20220306/Ape_Mutant_04_1646554302115.jpg",
      userImg: "https://d1don5jg7yw08.cloudfront.net/800x800/nft-images/20220306/Ape_Mutant_04_1646554302115.jpg",
      author: "Ape Mutants",
      avatar: "escapeImg",
      label: "ESCP",
      currentBid: "~",
      price: "2000",
      verified: true,
      status: "Auction",
    },
  ]);
  // 1. Get projectId
  const projectId = 'f80fc3d171a2b876fb5c372942a960be'

  // 2. Set chains
  const mainnet = {
    chainId: 56,
    name: 'BSC',
    currency: 'BNB',
    explorerUrl: 'https://bscscan.com',
    rpcUrl: 'https://bsc-dataseed.binance.org'
  }

  // 3. Create a metadata object
  const metadata = {
    name: 'Non Fungible Estate',
    description: 'My Website description',
    url: 'https://nonfungible.estate ', // origin must match your domain & subdomain
    icons: ['https://avatars.mywebsite.com/']
  }

  // 4. Create Ethers config
  const ethersConfig = defaultConfig({
    /*Required*/
    metadata,

    /*Optional*/
    enableEIP6963: true, // true by default
    enableInjected: true, // true by default
    enableCoinbase: true, // true by default
    rpcUrl: '...', // used for the Coinbase SDK
    defaultChainId: 1 // used for the Coinbase SDK
  })

  // 5. Create a AppKit instance
  createWeb3Modal({
    ethersConfig,
    chains: [mainnet],
    projectId,
    themeMode: 'light', // Optional - defaults to 'auto'
    enableAnalytics: true // Optional - defaults to your Cloud configuration
  })

  return (
    <>
      <ToastContainer />
      <Navbar />
      <Routes>
        <Route path="" element={<MainLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/discover" element={<Discover />} />
        </Route>
        <Route path="/buy/:nft_id" element={<BuyNowPage nftData={nftData} />} />
        <Route path="/sales/repnft" element={<BuyNowPage nftData={nftData} sales />} />
        <Route path="/sales/repnft/:referrer_address" element={<BuyNowPage nftData={nftData} sales />} />
        <Route path="/nfts" element={<Nfts nftData={nftData} />} />
      </Routes>
    </>
  );
}

export default App;
