import React from "react";

import { FiTwitter, FiInstagram, FiFacebook, FiMail } from "react-icons/fi";
import { TbRectangle } from "react-icons/tb";

import classes from "./Footer.module.css";
import { Link } from "react-router-dom";

import { logo } from "../../images";
import { Button, Heading, Text } from "../common";
import clsx from "clsx";

const Footer = () => {
  const socialLinks = [
    {
      logo: "https://cdn-icons-png.flaticon.com/128/2111/2111646.png",
      name: "Telegram",
      link: "#",
    },
    // {
    //   logo: <FiTwitter className={classes.socialIcon} />,
    //   name: "Twitter",
    //   link: "#",
    // },
    // {
    //   logo: <FiFacebook className={classes.socialIcon} />,
    //   name: "Facebook",
    //   link: "#",
    // },
    // {
    //   logo: <TbRectangle className={classes.socialIcon} />,
    //   name: "Mirror",
    //   link: "#",
    // },
  ];
  return (
    <div className={clsx(classes.footer)}>
      <footer className={clsx(classes.wrapper, "container")}>
        <div className={clsx(classes.itemContainer, classes.logoContainer)}>
          <img src={logo} alt="#" className={classes.logo} />
          <Text xl neutral5>
            Infinite possibilities await those who dare to explore
          </Text>
          <Text justify={true} xs neutral5>
            By utilizing this website, the user expressly acknowledges and consents to all stipulations outlined in the TERMS AND CONDITIONS, PRIVACY POLICY, and DISCLAIMERS, as well as any implicit or explicit provisions that may or may not be explicitly stated herein or on affiliated subdomains. The user is deemed to possess comprehensive understanding of the inherent properties and characteristics of Decentralized NFTs, including but not limited to the absence of regulatory frameworks, potential security vulnerabilities, scalability limitations, user experience challenges, support and dispute resolution mechanisms, market manipulation risks, lack of quality control measures, interoperability issues, gas fees, transaction costs, and environmental impact. With full awareness of these factors, the user knowingly and voluntarily proceeds to purchase the NFT(s) in question.
          </Text>
        </div>{" "}
        <div className={clsx(classes.itemContainer)}>
          <Heading neutral7 className={classes.heading}>
            QUICKLINKS
          </Heading>

          <Link to="/TERMS.pdf" className={classes.item}>
          Terms of Service
          </Link>
          <Link to="/PRIVACY.pdf" className={classes.item}> Privacy Policy</Link>
          <Link to="/TERMS-OF-CREATOR.pdf" className={classes.item}>Terms of Creator</Link>
          <Link to="/nfts" className={classes.item}>
            Marketplace
          </Link>
          <Link to="/sales/repnft" className={classes.item}>
            Recently Listed
          </Link>
        </div>{" "}
        <div className={clsx(classes.itemContainer)}>
          <Heading neutral7 className={classes.heading}>
            SUPPORT
          </Heading>
          <Link to="mailto:support@nonfungible.estate" className={classes.item}>
            support@nonfungible.estate
          </Link>{" "}

        </div>{" "}
        <div className={clsx(classes.itemContainer)}>
          <Heading neutral7 className={classes.heading}>
            SOCIAL
          </Heading>
          {socialLinks.map((el, i) => (
            <a
              key={i}
              href={el.link}
              target="_blank"
              rel="noreferrer"
              className={classes.item}
            >
              <img width={20} src={el.logo}></img> <span>{el.name}</span>
            </a>
          ))}
        </div>
      </footer>
      <div className={classes.bottomBar}>
        <div className={clsx(classes.copyRightContainer, "container")}>
          <Text neutral4 className={clsx(classes.item, classes.copyRight)}>
            Copyright © 2024 Non~Fungible Estate. All rights reserved.
          </Text>
          {/* <div className={classes.cookies}>
            {" "}
            <Text neutral4 className={clsx(classes.item, classes.cookiesText)}>
              We use cookies for better service.
            </Text>
            <Button
              transparent
              onClick={() => { }}
              className={classes.acceptButton}
            >
              ACCEPT
            </Button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Footer;
