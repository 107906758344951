import React from "react";
import classes from "./Creativity.module.css";
import clsx from "clsx";
import { LuCircleDot } from "react-icons/lu";

import { Button, Heading, Text } from "components/common";
import { collecting, earnTogether, mintFees } from "images";

const Creativity = () => {
  const data = [
    {
      video: collecting,
      title: "Your NFT Collection, your way.",
      infos: [
        "Take full control of your NFT collection with our customizable showcases, personalized profiles, and flexible management tools. Display your unique digital assets with pride, and share your passion with the world. Curate your collection to reflect your style and showcase your favorite pieces with ease.",
      ],
      features: [

      ],
      learnMore: "",
    },
    {
      video: mintFees,
      title: "Lowest mint fees",
      infos: [
        "Create and mint your NFTs without breaking the bank, with our competitive and transparent fees that let you keep more of your hard-earned revenue. Say goodbye to excessive costs and hello to more creative freedom, with the lowest NFT mint fees in the industry. Start minting and growing your collection today, without sacrificing your artistic vision.",
      ],
      learnMore: "",
    },
    {
      video: earnTogether,
      title: "Create together, earn together.",
      infos: [
        "Bring your digital creations to life by minting unique NFTs, listing them for sale, and trading them with a vibrant community of collectors and enthusiasts. Earn revenue from your artistic endeavors, and discover new opportunities for passive income through royalties and resales. Unlock the full potential of your NFTs and turn your creativity into a lucrative career.",
      ],
      learnMore: "",
    },
  ];
  return (
    <section className={classes.wrapper}>
      <div className={clsx(classes.container, "container")}>
        <div className={classes.header}>
          <Heading h1 neutral7 textCenter>
            Monetize Your and Others' Creativity As Well
          </Heading>
          <Text xl2 textCenter neutral5>
            Unlock the full potential of your digital art, music, and collectibles by buying, selling, and trading NFTs on our platform. Empower creators to turn their imagination into valuable assets, and discover new talent to support and collect. Together, let's build a thriving ecosystem where creativity and innovation are rewarded.
          </Text>
        </div>

        <div className={classes.cards}>
          {data.map((el, i) => (
            <div
              className={clsx(classes.card, (i + 1) % 2 === 0 && classes.card2)}
              key={i}
            >
              <div className={classes.videoContainer}>
                <video
                  className={classes.video}
                  autoPlay
                  muted
                  loop
                  playsInline
                >
                  <source src={el.video} type="video/mp4" />
                </video>
              </div>
              <div className={classes.infoContainer}>
                <Heading h2 neutral7 className={classes.title}>
                  {el.title}
                </Heading>
                <div className={classes.infos}>
                  {el?.infos?.map((info, i) => (
                    <Text justify semiBold xl neutral5 key={i}>
                      {info}
                    </Text>
                  ))}
                </div>

                {el.features && (
                  <div className={classes.features}>
                    {el.features.map((feature, i) => (
                      <div className={classes.feature} key={i}>
                        <LuCircleDot className={classes.dot} />
                        <Text semiBold xl neutral8 key={i}>
                          {feature}
                        </Text>
                      </div>
                    ))}
                  </div>
                )}
                {/* <Button
                  transparent
                  href={el.learnMore}
                  className={classes.button}
                >
                  learn more
                </Button> */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Creativity;
