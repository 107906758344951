import React, { useEffect, useState } from "react";
import classes from "./Sale.module.css";
import { LuCircleDot } from "react-icons/lu";
import { Button, Heading, Text } from "components/common";
import { MdClose } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { FiMinus, FiPlus } from "react-icons/fi";
import clsx from "clsx";
import Checkbox from "components/common/CheckBox/CheckBox";
import { IoShareSocialOutline } from "react-icons/io5";
import { FaLocationDot } from "react-icons/fa6";
import { LuRefreshCcw } from "react-icons/lu";
import {
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers/react";
import { toast } from "react-toastify";
import { BrowserProvider, Contract, MaxInt256, parseEther } from "ethers";

const Sale = () => {
  const navigate = useNavigate();
  const [price, setPrice] = useState(329.0);
  const [tokenName, setTokenName] = useState("REPNFT");
  const [quantity, setQuantity] = useState(1);
  const [agreeWithTos, setAgreeWithTos] = useState(false);
  const [isPurchased, setIsPurchased] = useState(false);
  const increaseQuantity = () => {
    setQuantity((prev) => prev + 1);
  };

  const decreaseQuantity = () => {
    setQuantity((prev) => (prev > 1 ? prev - 1 : 1));
  };

  const [agreeToTermsAndCondition, setAgereeToTermsAndCondition] = useState(
    localStorage.getItem("agreeToTerms") == "true"
  );
  const { open } = useWeb3Modal();
  const { referrer_address } = useParams();
  const { walletProvider } = useWeb3ModalProvider();
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const [usdtAllowance, setUsdtAllowance] = React.useState(0);
  const [unsAlowance, setUnsAllowance] = React.useState(0);
  const details = [
    { key: "Number of rooms", value: `45` },
    { key: "Delux room size", value: "345 Sq Ft " },
    { key: "Junior Suite", value: "700 Sq Ft " },
  ];

  let presale_address = "0x147F2A05a2f6CB24A3b755e2A70C228376AA783f";

  const erc20Abi = [
    "function name() view returns (string)",
    "function symbol() view returns (string)",
    "function decimals() view returns (uint8)",
    "function totalSupply() view returns (uint)",
    "function allowance(address owner, address spender) view returns (uint)",
    "function balanceOf(address) view returns (uint)",
    "function transfer(address to, uint amount)",
    "function approve(address spender, uint amount)",
    "event Transfer(address indexed from, address indexed to, uint amount)",
  ];

  const saleAbi = [
    {
      type: "function",
      name: "initialize",
      inputs: [
        { name: "_usdt_token", type: "address", internalType: "address" },
        { name: "_vault", type: "address", internalType: "address" },
        { name: "_sale", type: "address", internalType: "address" },
        { name: "_usdt_rate", type: "uint256", internalType: "uint256" },
      ],
      outputs: [],
      stateMutability: "nonpayable",
    },
    {
      type: "function",
      name: "isDiscounted",
      inputs: [{ name: "", type: "address", internalType: "address" }],
      outputs: [{ name: "", type: "bool", internalType: "bool" }],
      stateMutability: "view",
    },
    {
      type: "function",
      name: "isInitialized",
      inputs: [],
      outputs: [{ name: "", type: "bool", internalType: "bool" }],
      stateMutability: "view",
    },
    {
      type: "function",
      name: "isPurchased",
      inputs: [{ name: "", type: "address", internalType: "address" }],
      outputs: [{ name: "", type: "bool", internalType: "bool" }],
      stateMutability: "view",
    },
    {
      type: "function",
      name: "item",
      inputs: [],
      outputs: [{ name: "", type: "address", internalType: "contract Item" }],
      stateMutability: "view",
    },
    {
      type: "function",
      name: "owner",
      inputs: [],
      outputs: [{ name: "", type: "address", internalType: "address" }],
      stateMutability: "view",
    },
    {
      type: "function",
      name: "propertyPurchase",
      inputs: [
        { name: "amount", type: "uint256", internalType: "uint256" },
        { name: "receiver", type: "address", internalType: "address" },
        { name: "referrer", type: "address", internalType: "address" },
      ],
      outputs: [{ name: "success", type: "bool", internalType: "bool" }],
      stateMutability: "nonpayable",
    },
    {
      type: "function",
      name: "recoverAccidentallySentTokens",
      inputs: [
        { name: "_tokenAddress", type: "address", internalType: "address" },
        { name: "_value", type: "uint256", internalType: "uint256" },
      ],
      outputs: [],
      stateMutability: "nonpayable",
    },
    {
      type: "function",
      name: "saleContract",
      inputs: [],
      outputs: [{ name: "", type: "address", internalType: "address" }],
      stateMutability: "view",
    },
    {
      type: "function",
      name: "setIsPurchased",
      inputs: [
        { name: "_user", type: "address", internalType: "address" },
        { name: "_status", type: "bool", internalType: "bool" },
      ],
      outputs: [],
      stateMutability: "nonpayable",
    },
    {
      type: "function",
      name: "setItem",
      inputs: [{ name: "_item", type: "address", internalType: "address" }],
      outputs: [],
      stateMutability: "nonpayable",
    },
    {
      type: "function",
      name: "totalPurchased",
      inputs: [{ name: "", type: "address", internalType: "address" }],
      outputs: [{ name: "", type: "uint256", internalType: "uint256" }],
      stateMutability: "view",
    },
    {
      type: "function",
      name: "totalSold",
      inputs: [],
      outputs: [{ name: "", type: "uint256", internalType: "uint256" }],
      stateMutability: "view",
    },
    {
      type: "function",
      name: "updateUsdtRate",
      inputs: [
        { name: "_usdt_rate", type: "uint256", internalType: "uint256" },
      ],
      outputs: [],
      stateMutability: "nonpayable",
    },
    {
      type: "function",
      name: "usdt_rate",
      inputs: [],
      outputs: [{ name: "", type: "uint256", internalType: "uint256" }],
      stateMutability: "view",
    },
    {
      type: "function",
      name: "usdt_token",
      inputs: [],
      outputs: [{ name: "", type: "address", internalType: "contract IERC20" }],
      stateMutability: "view",
    },
    {
      type: "function",
      name: "vault",
      inputs: [],
      outputs: [{ name: "", type: "address", internalType: "address" }],
      stateMutability: "view",
    },
    {
      type: "function",
      name: "whitelistSeller",
      inputs: [
        { name: "_user", type: "address", internalType: "address" },
        { name: "_status", type: "bool", internalType: "bool" },
      ],
      outputs: [],
      stateMutability: "nonpayable",
    },
    {
      type: "function",
      name: "whitelistedSellers",
      inputs: [{ name: "", type: "address", internalType: "address" }],
      outputs: [{ name: "", type: "bool", internalType: "bool" }],
      stateMutability: "view",
    },
    {
      type: "event",
      name: "ItemPurchased",
      inputs: [
        {
          name: "buyer",
          type: "address",
          indexed: true,
          internalType: "address",
        },
        {
          name: "amount",
          type: "uint256",
          indexed: false,
          internalType: "uint256",
        },
      ],
      anonymous: false,
    },
  ];
  async function approveToken(tokenAddress, amount, allowance_set) {
    toast.loading("Approving token");
    const ethersProvider = new BrowserProvider(walletProvider);
    const signer = await ethersProvider.getSigner();
    try {
      const contract = new Contract(tokenAddress, erc20Abi, signer);
      const tx = await contract.approve(presale_address, amount);
      await tx.wait();
      toast.success("Token approved");
      allowance_set(amount);
      setTimeout(() => {
        toast.dismiss();
      }, 3000);
    } catch (e) {
      // toast.error("Approval failed", { autoClose: 3000 });
      console.log(e);
      setTimeout(() => {
        window.location.reload();
      }, 13000);

      setTimeout(() => {
        toast.dismiss();
      }, 3000);
    }
  }

  async function checkApproval(tokenAddress) {
    const ethersProvider = new BrowserProvider(walletProvider);
    const signer = await ethersProvider.getSigner();
    const contract = new Contract(tokenAddress, erc20Abi, signer);
    const allowance = await contract.allowance(address, presale_address);
    return allowance;
  }

  async function checkPurchase(address) {
    const ethersProvider = new BrowserProvider(walletProvider);
    const signer = await ethersProvider.getSigner();
    const contract = new Contract(presale_address, saleAbi, signer);
    const purchased = await contract.isPurchased(address);
    setIsPurchased(purchased > 0);
    return purchased;
  }

  async function purchase() {
    toast.loading("Purchasing property");
    try {
      const ethersProvider = new BrowserProvider(walletProvider);
      const signer = await ethersProvider.getSigner();
      const contract = new Contract(presale_address, saleAbi, signer);

      console.log(` 
        Purchasing ${quantity} with ${address} and ${referrer_address}
        `);
      const tx = await contract.propertyPurchase(
        quantity,
        address,
        referrer_address
          ? referrer_address
          : "0x918ECdC7FcFD5e9Ef1CE2f1D9a357786f9ABc89e"
      );
      await tx.wait();
      toast.success("Purchase successful");
      setTimeout(() => {
        toast.dismiss();
      }, 3000);
    } catch (e) {
      toast.error(
        "Purchase failed (Make sure you have enough balance in your wallet)",
        { autoClose: 3000 }
      );
      setTimeout(() => {
        toast.dismiss();
      }, 3000);
      // let parsed_error =JSON.parse(JSON.stringify(e));
      // alert(parsed_error.info.error.data.message);
    }
  }

  async function runner() {
    let allowance = await checkApproval(
      "0x55d398326f99059fF775485246999027B3197955"
    );

    if (address) {
      await checkPurchase(address);
    }
    setUsdtAllowance(allowance);

    let uns_allowance = await checkApproval(
      "0xEcf99aB23C11ddc6520252105308C251001AEfBB"
    );
    setUnsAllowance(uns_allowance);
  }
  useEffect(() => {
    runner();
  }, [isConnected]);

  useEffect(() => {
    // call runner every 5 seconds
    const interval = setInterval(() => {
      runner();
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  const [agreeToTerms, setAgreeToTerms] = React.useState(
    localStorage.getItem("agreeToTerms") == "true"
  );
  const [isViewedTerms, setIsViewedTerms] = React.useState(
    localStorage.getItem("isViewedTerms") == "true"
  );
  function purchasebutton() {
    if (!agreeToTermsAndCondition) {
      return {
        title: "Agree to Terms and Conditions",
        onClick: () => {},
      };
    }

    // if (!isViewedTerms) {
    //   return {
    //     title: "View Terms and Conditions",
    //     onClick: () => {
    //       localStorage.setItem("isViewedTerms", true);

    //       setIsViewedTerms(true);
    //     },
    //   };
    // }

    // if (!agreeToTerms) {
    //   return {
    //     title: "Agree to Terms and Conditions",
    //     onClick: () => {
    //       localStorage.setItem("agreeToTerms", true);
    //       setAgreeToTerms(true);
    //     },
    //   };
    // }

    if (!isConnected) {
      return {
        title: "Connect Wallet",
        onClick: () => {
          open();
        },
      };
    }
    if (usdtAllowance < parseEther((quantity * price).toString())) {
      return {
        title: "Approve USDT",
        onClick: () => {
          approveToken(
            "0x55d398326f99059fF775485246999027B3197955",
            MaxInt256,
            setUsdtAllowance
          );
        },
      };
    }

    // if (unsAlowance < parseEther((quantity * price).toString())) {
    //   return {
    //     title: "Approve UNS",
    //     onClick: () => {
    //       approveToken("0xEcf99aB23C11ddc6520252105308C251001AEfBB", MaxInt256, setUnsAllowance);
    //     },
    //   };
    // }
    else {
      return {
        title: "Purchase ",
        onClick: () => {
          purchase();
        },
      };
    }
  }

  const [isCopied, setIsCopied] = React.useState(false);

  //
  //
  //
  return (
    <div className={classes.saleNow}>
      <div className={classes.infoContainer}>
        <div className={classes.header}>
          <Heading h3 neutral7>
            {tokenName} Sale
          </Heading>
          <button className={classes.closeButton} onClick={() => navigate(-1)}>
            <MdClose className={classes.closeIcon} />
          </button>
        </div>
        <Text xl neutral5 semiBold>
          {/* Confirm the transaction to sale
          <span className={classes.escape}> REPNFT</span> */}
          Purchase {tokenName}
        </Text>
        {/* <div className={classes.feature}>
          <LuCircleDot className={classes.dot} />
          <Text semiBold xl neutral7>
            Learn how to buy on Non fungible estate
          </Text>
        </div> */}
      </div>
      <div className={classes.shareContainer}>
        {/* if you pass href it will work like <a/>> tag if you pass onClick it will work like button if you pass to it will work like <Link/> */}
        {1 == 1 && (
          <Button
            onClick={() => {
              if (!address) {
                open();
                return;
              }

              if (!isPurchased) {
                toast.error("Please purchase to get link", { autoClose: 3000 });
                return;
              }
              navigator.clipboard.writeText(
                `https://nonfungible.estate/sales/repnft/${address}`
              );
              setIsCopied(true);
              setTimeout(() => {
                setIsCopied(false);
              }, 3000);
            }}
            neutral7
            className={clsx(classes.shareButton)}
          >
            <IoShareSocialOutline className={classes.icon} />
            {isCopied
              ? "Copied"
              : !isPurchased
              ? "Purchase to get link"
              : "Copy Referral link"}
          </Button>
        )}
        {/* <Button neutral7 href="#" className={clsx(classes.shareButton)}>
          <FaLocationDot className={classes.icon} />
          Google Map
        </Button>
        <Button neutral7 href="#" className={clsx(classes.shareButton)}>
          <LuRefreshCcw className={classes.icon} />
          Reset
        </Button> */}
      </div>
      <div className={classes.priceContainer}>
        <Text lg neutral5>
          Total price
        </Text>
        <div className={classes.inputContainer}>
          <Heading h3 neutral7>
            {price} x {quantity}
          </Heading>
          <Heading h3 neutral7>
            {quantity * price} USDT
          </Heading>
        </div>
        <div className={classes.agreeWithTos}>
          <Checkbox
            checked={agreeToTermsAndCondition}
            setChecked={setAgereeToTermsAndCondition}
            label={
              <Text lg neutral7 justify className={classes.agreeText}>
                I agree to all{" "}
                <a href="/TERMS.pdf" target="_blank" rel="  noreferrer">
                  Terms of Service
                </a>
                ,{" "}
                <a href="/PRIVACY.pdf" target="_blank" rel=" noreferrer">
                  Privacy Policy
                </a>
                ,{" "}
                <a
                  href="/TERMS-OF-CREATOR.pdf"
                  target="_blank"
                  rel=" noreferrer"
                >
                  Terms of Creator
                </a>{" "}
                or anything that{" "}
                <a
                  href="https://nonfungible.estate"
                  target="_blank"
                  rel="noreferrer"
                  className={classes.link}
                >
                  https://nonfungible.estate
                </a>{" "}
                and its subdomains have/haven't expressly mentioned/not
                mentioned and I'm aware of all the properties and
                characteristics of Decentalized NFT. I'm consciously buying
                this/these NFT/s with complete knowledge about the lack of
                regulations, security risks, scalability issues, user experience
                challenges, support and dispute resolution, market manipulation,
                lack of quality control, interoperability issues, gas fees and
                transaction costs and the environmental impact.
              </Text>
            }
          />
        </div>
        <div className={classes.buttonContainer}>
          <Button
            onClick={purchasebutton().onClick}
            neutral7
            className={clsx(classes.confirmButton, classes.button)}
          >
            {purchasebutton().title}
          </Button>

          <div className={classes.plusMinus}>
            <Button
              neutral7
              className={clsx(classes.minusButton, classes.button)}
              onClick={decreaseQuantity}
            >
              <FiMinus className={classes.minusIcon} />
            </Button>
            <Button
              neutral7
              className={clsx(classes.plusButton, classes.button)}
              onClick={increaseQuantity}
            >
              <FiPlus className={classes.plusIcon} />
            </Button>
          </div>
        </div>

        <Text justify sm neutral5 className={classes.info}>
          We are laying the groundwork for web3 ~ the next generation of the
          internet full of limitless possibilities. Join the millions of
          creators, collectors, and curators who are on this journey with you.
        </Text>
      </div>
    </div>
  );
};

export default Sale;
